<template>
  <div class="page inPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">Picker</div>
    </div>
    <div class="pageBody">
      <lee-picker
          v-model:show="show1"
          title="标题"
          :columns="columns1"
          @onConfirm="onConfirm1"
      />
      <lee-picker
          v-model:show="show2"
          title="标题"
          :columns="columns2"
          @onConfirm="onConfirm2"
      />
      <lee-picker
          v-model:show="show3"
          title="标题"
          :columns="columns1"
          @onOpen="onOpen3"
          @onClose="onClose3"
          @onConfirm="onConfirm3"
          @onCancel="onCancel3"
          @onChange="onChange3"
      />
      <lee-picker
          v-model:show="show4"
          title="标题"
          :columns="columns2"
          v-model="selectedValues"
          @onConfirm="onConfirm4"
      />
      <lee-picker
          v-model:show="show5"
          title="标题"
          :columns="columns5"
          v-model="selectedValues5"
          @onConfirm="onConfirm5"
      />
      <lee-cell-group title="基础用法" inset>
        <lee-cell title="基础用法" is-link @click="show1 = true" :footer="result1"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="多列选择" inset>
        <lee-cell title="多列选择" is-link @click="show2 = true" :footer="result2"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="监听事件" inset>
        <lee-cell title="监听事件" is-link @click="show3 = true" :footer="result3"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="双向绑定" inset>
        <lee-cell title="双向绑定" is-link @click="show4 = true" :footer="result4"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="级联选择" inset>
        <lee-cell title="级联选择" is-link @click="show5 = true" :footer="result5"></lee-cell>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {ref} from 'vue'
import {showToast} from '@/leeplus';
import {useRouter} from 'vue-router'

const router = useRouter()
const back = () => {
    router.push('/')
}
const show1 = ref(false)
const show2 = ref(false)
const show3 = ref(false)
const show4 = ref(false)
const show5 = ref(false)

const result1 = ref('')
const result2 = ref('')
const result3 = ref('')
const result4 = ref('')
const result5 = ref('')

const columns1 = [
    {text: '杭州', value: 'Hangzhou'},
    {text: '宁波', value: 'Ningbo'},
    {text: '温州', value: 'Wenzhou'},
    {text: '绍兴', value: 'Shaoxing'},
    {text: '湖州', value: 'Huzhou'},
    {text: '金华', value: 'Jinhua'},
    {text: '台州', value: 'Taizhou'},
];
const columns2 = [
    // 第一列
    [
        {text: '周一', value: 'Monday'},
        {text: '周二', value: 'Tuesday'},
        {text: '周三', value: 'Wednesday'},
        {text: '周四', value: 'Thursday'},
        {text: '周五', value: 'Friday'},
    ],
    // 第二列
    [
        {text: '上午', value: 'Morning'},
        {text: '下午', value: 'Afternoon'},
        {text: '晚上', value: 'Evening'},
    ],
];

const columns5 = [{
    text: '福建',
    value: 'fujian',
    children: [{
        text: '福州市',
        value: 'fuzhou',
        children: [
            {text: '鼓楼区', value: 'gulou',},
            {text: '台江区', value: 'taijiang',},
            {text: '仓山区', value: 'canshan',},
            {text: '马尾区', value: 'mawei',},
        ]
    },
        {
            text: '厦门市',
            value: 'xiamen',
            children: [
                {
                    text: '思明区',
                    value: 'siming'
                },
                {text: '湖里区',value:'huli'},
                {text: '海沧区',value:'haican'},
                {text: '集美区',value:'jimei'},
                {text: '翔安区',value:'xianan'},
                {text: '同安区',value:'tongan'},
            ]
        },
        {
            text: '宁德市',
            value:'ningde',
            children: [
                {text: '蕉城区',value:'jiancheng'}
            ]
        },
        {
            text: '莆田市',
            value:'putian',
            children: [
                {text: '仙游县',value:'xianyou'}
            ]
        },
        {
            text: '泉州市',
            value:'quanzhou',
            children: [
                {text: '丰泽区',value:'fengze'}
            ]
        },
        {
            text: '漳州市',
            value:'zhangzhou',
            children: [
                {text: '芗城区',value:'pengxiang'}
            ]
        },
        {
            text: '龙岩市',
            value:'longyan',
            children: [
                {text: '新罗区',value:'xinnuo'},
                {text: '永定区',value:'yongding'},
            ]
        },
        {
            text: '三明市',
            value:'sanming',
            children: [
                {text: '永安市',value:'yongan'}
            ]
        },
        {
            text: '南平市',
            value:'nanping',
            children: [
                {text: '延平区',value:'yanping'}
            ]
        },

    ]
}, {
    text: '广东',
    value:'guangdong',
    children: [{
        text: '广州市',
        value:'guangzhou',
        children: [
            {text: '荔湾区',value:'liwan'},
            {text: '越秀区',value:'yuexiu'},
            {text: '海珠区',value:'haizhu'},
            {text: '天河区',value:'tianhe'},
        ]
    },
        {
            text: '韶关市',
            value:'saoguan',
            children: [
                {text: '武江区',value:'wujiang'},
                {text: '浈江区',value:'zhengjiang'},
                {text: '曲江区',value:'qujiang'},
                {text: '始兴县',value:'shixing'},
                {text: '仁化县',value:'renhua'},
                {text: '翁源县',value:'wenyuan'},
            ]
        },
        {
            text: '深圳市',
            value:'shengzhen',
            children: [
                {text: '罗湖区',value:'nuohu'},
                {text: '福田区',value:'futian'},
                {text: '南山区',value:'nanshan'},
                {text: '宝安区',value:'baoan'},
                {text: '龙岗区',value:'longgang'},
                {text: '盐田区',value:'yantian'},
            ]
        },
        {
            text: '珠海市',
            value:'zhuhai',
            children: [
                {text: '香洲区',value:'xiangzhou'},
                {text: '斗门区',value:'doumen'},
                {text: '金湾区',value:'jiangwang'},
            ]
        },
        {
            text: '汕头市',
            value:'santou',
            children: [
                {text: '龙湖区',value:'longhu'}
            ]
        },
        {
            text: '佛山市',
            value:'fushan',
            children: [
                {text: '高明区',value:'gaoming'}
            ]
        },
        {
            text: '江门市',
            value:'jiangmen',
            children: [
                {text: '蓬江区',value:'pengjiang'},
                {text: '江海区',value:'jianghai'},
            ]
        },
        {
            text: '湛江市',
            value:'zanjiang',
            children: [
                {text: '赤坎区',value:'cikan'}
            ]
        },
        {
            text: '茂名市',
            value:'maoming',
            children: [
                {text: '茂南区',value:'mannan'}
            ]
        },

    ]
}, {
    text: '浙江',
    value:'zejiang',
    children: [{
        text: '杭州市',
        value:'hangzhou',
        children: [
            {text: '上城区',value:'shangcheng'},
            {text: '下城区',value:'xiacheng'},
            {text: '江干区',value:'jianggang'},
            {text: '拱墅区',value:'gongshu'},
        ]
    },
        {
            text: '宁波市',
            value:'ningbo',
            children: [
                {text: '海曙区'},
                {text: '江东区'},
                {text: '江北区'},
                {text: '北仑区'},
                {text: '镇海区'},
                {text: '鄞州区'},
            ]
        },
        {
            text: '温州市',
            children: [
                {text: '鹿城区'},
                {text: '龙湾区'},
                {text: '瓯海区'},
                {text: '洞头县'},
                {text: '永嘉县'},
                {text: '平阳县'},
            ]
        },
        {
            text: '嘉兴市',
            children: [
                {text: '南湖区'},
                {text: '秀洲区'},
                {text: '嘉善县'},
            ]
        },
        {
            text: '湖州市',
            children: [
                {text: '吴兴区'}
            ]
        },
        {
            text: '绍兴市',
            children: [
                {text: '嵊州市'}
            ]
        },
        {
            text: '金华市',
            children: [
                {text: '婺城区'},
                {text: '金东区'},
            ]
        },
        {
            text: '衢州市',
            children: [
                {text: '衢江区'}
            ]
        },
        {
            text: '丽水市',
            children: [
                {text: '莲都区'}
            ]
        },

    ]
}]


//事件
const getResult = (rs) => {
    let textArr = rs.text
    let result = ''
    let fh = '/'
    for (let i = 0; i < textArr.length; i++) {
        if (i === textArr.length - 1) {
            fh = ''
        }
        result += textArr[i] + fh
    }
    return result
}
const onConfirm1 = (rs) => {
    result1.value = getResult(rs)
}
const onConfirm2 = (rs) => {
    result2.value = getResult(rs)
}
const onOpen3 = () => {
    showToast('打开')
}
const onClose3 = () => {
    showToast('关闭')
}
const onConfirm3 = (rs) => {
    showToast('当前选中值：' + rs.value)
    result3.value = getResult(rs)
}

const onCancel3 = () => {
    showToast('取消')
}
const onChange3 = (rs) => {
    showToast('当前选中值：' + rs.value)
}
const onConfirm4 = (rs) => {
    result4.value = getResult(rs)
}
const selectedValues = ref(['Wednesday', 'Afternoon']);

const onConfirm5 = (rs) => {
    result5.value = getResult(rs)
}
const selectedValues5 = ref(['Fujian', 'Xiamen']);

</script>
<style>


</style>
